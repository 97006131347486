import React, { useState } from 'react';
import { Container, Grid, Typography, Box, Button, Modal, Card, CardContent } from '@mui/material';

const ProfileDetails = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  return (
    <Container sx={{ marginTop: 5 }}>
      {/* Profile Card with Linear Gradient */}
      <Card 
        sx={{ 
          maxWidth: 700, 
          margin: '0 auto', 
          background: 'linear-gradient(135deg, #f7d4c5 0%, #fbe8a6 100%)' 
        }}
      >
        <CardContent>
          {/* Title Inside the Card */}
          <Typography variant="h4" align="center" gutterBottom>
            Profile Details
          </Typography>

          <Grid container spacing={3}>
            {/* Left Side: Profile Details with Top Margin */}
            <Grid item xs={12} sm={6}>
              <Box p={3}>
                <Typography variant="h6">Name: Roshni</Typography>
                <Typography variant="h6" mt={0.5}>Phone Number: +1234567890</Typography>
                <Typography variant="h6" mt={0.5}>Email: roshni@example.com</Typography>
                <Typography variant="h6" mt={0.5}>Subjects: Mathematics, Science</Typography>
                <Typography variant="h6" mt={0.5}>Subscription Status: Active</Typography>
                <Typography variant="h6" mt={0.5}>Valid Thru: 01/01/2025</Typography>
                <Typography variant="h6" mt={0.5}>Expiry Thru: 01/01/2026</Typography>
                <Typography variant="h6" mt={0.5}>Days Left: 35</Typography>
              </Box>
            </Grid>

            {/* Right Side: Image Upload Frame */}
            <Grid item xs={12} sm={6}>
              <Box sx={{ p: 3, textAlign: 'center' }}>
                {/* Frame for Image Upload */}
                <Box
                  sx={{
                    width: 150,
                    height: 150,
                    border: '2px dashed #ccc',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0 auto',
                    marginBottom: 2,
                    marginTop: 8
                  }}
                >
                  <Button variant="text" component="label">
                    Choose File
                    <input type="file" hidden />
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>

          {/* Request Renewal Button Inside the Card */}
          <Box mt={4} textAlign="center">
            <Button variant="contained" color="primary" onClick={handleModalOpen}>
              Request Renewal
            </Button>
          </Box>
        </CardContent>
      </Card>

      {/* Profile Renewal Modal */}
      <Modal open={openModal} onClose={handleModalClose} aria-labelledby="request-renewal-modal" aria-describedby="modal-description">
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" gutterBottom>
            Request Renewal
          </Typography>
          <Typography variant="body1" paragraph>
            Are you sure you want to request a renewal of your subscription?
          </Typography>
          <Button variant="contained" color="primary" onClick={handleModalClose}>Confirm</Button>
          <Button variant="outlined" color="secondary" onClick={handleModalClose} sx={{ ml: 2 }}>Cancel</Button>
        </Box>
      </Modal>
    </Container>
  );
};

// Modal Style
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
};

export default ProfileDetails;
