import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ViewAnnouncements.css'; // Ensure you create this CSS file for styling
import Navbar from './Navbar';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Tooltip, Snackbar } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const ViewAnnouncements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [announcementToDelete, setAnnouncementToDelete] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false); // For snackbar state
  const [snackbarMessage, setSnackbarMessage] = useState(''); // For snackbar message
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch announcements from the API
    const fetchAnnouncements = async () => {
      try {
        const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Anouncement/All_Anouncement_Admin', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('token'),
          },
          body: JSON.stringify({}),
        });

        const data = await response.json();
        const mappedAnnouncements = data.list.map((announcement, index) => ({
          id: index + 1,
          class: `Class ${announcement.classId}`,
          section: `Section ${announcement.sectionId}`,
          title: announcement.title,
          message: announcement.message,
          createdOn: announcement.createdOn,
          createdBy: announcement.createdBy,
          createdFor: announcement.createdFor,
        }));
        setAnnouncements(mappedAnnouncements);
      } catch (error) {
        console.error('Error fetching announcements:', error);
      }
    };

    fetchAnnouncements();
  }, []);

  const handleCreateAnnouncement = () => {
    navigate('/announcements'); // Navigate to the Announcement page
  };

  const handleDeleteClick = (id) => {
    setAnnouncementToDelete(id);
    setOpenDialog(true); // Open the confirmation dialog
  };

  const handleDeleteConfirm = async () => {
    if (announcementToDelete) {
      try {
        const response = await fetch(`https://arizshad-002-site5.ktempurl.com/api/Anouncement/Id`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('token'),
          },
          body: JSON.stringify({ id: announcementToDelete }),
        });

        if (response.ok) {
          setAnnouncements((prevAnnouncements) =>
            prevAnnouncements.filter((announcement) => announcement.id !== announcementToDelete)
          );
          setSnackbarMessage('Announcement deleted successfully!');
          setSnackbarOpen(true); // Show Snackbar message
          setOpenDialog(false); // Close the dialog after deleting
        } else {
          console.error('Failed to delete the announcement');
        }
      } catch (error) {
        console.error('Error deleting announcement:', error);
      }
    }
  };

  const handleDeleteCancel = () => {
    setOpenDialog(false); // Close the dialog without deleting
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false); // Close the Snackbar
  };

  return (
    <div className="view-announcements">
      <div className="header">
        <h2 className="view-announcements-header">View Announcements</h2>
        <button className="create-announcement-button" onClick={handleCreateAnnouncement}>
          Create Announcement
        </button>
      </div>

      <div className="announcements-list">
        {announcements.map((announcement) => (
          <div key={announcement.id} className="announcement-card">
            <div className="announcement-header">
              <span className="announcement-class">Class: {announcement.class}</span>
              <Tooltip title="Delete Announcement" arrow>
                <button
                  className="delete-icon-button"
                  onClick={() => handleDeleteClick(announcement.id)}
                >
                  <DeleteIcon />
                </button>
              </Tooltip>
            </div>
            <h3 className="announcement-title">{announcement.title}</h3>
            <p className="announcement-message">{announcement.message}</p>
            <div className="announcement-details">
              <span><strong>Created On:</strong> {announcement.createdOn}</span>
              <span><strong>Created By:</strong> {announcement.createdBy}</span>
              <span><strong>Created For:</strong> {announcement.createdFor}</span>
            </div>
          </div>
        ))}
      </div>

      {/* Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleDeleteCancel}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this announcement?</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteCancel}
            color="primary"
            variant="contained" 
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="secondary"
            variant="contained" 
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for success message */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        severity="success"
      />
    </div>
  );
};

export default ViewAnnouncements;
