import React, { useState, useEffect } from 'react';
import './ViewAssignment.css';

function ViewAssignment() {
  const [assignments, setAssignments] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [filteredAssignments, setFilteredAssignments] = useState([]);
  const [editingAssignment, setEditingAssignment] = useState(null);
  const [checkedAssignments, setCheckedAssignments] = useState([]);
  const [assignmentsDropdown, setAssignmentsDropdown] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState("");
 const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');

  const truncateDescription = (description, wordLimit = 3) => {
    const words = description.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return description;
  };

  useEffect(() => {
    fetchTeachers();
  }, []);

  useEffect(() => {
    if (selectedTeacher) {
      fetchClasses(selectedTeacher);
    } else {
      setClasses([]);
    }
  }, [selectedTeacher]);

  useEffect(() => {
    if (selectedClass) {
      fetchSections(selectedClass);
    } else {
      setSections([]);
    }
  }, [selectedClass]);

  useEffect(() => {
    if (selectedSection) {
      fetchSubjects(selectedClass, selectedSection, selectedTeacher);
    } else {
      setSubjects([]);
    }
  }, [selectedSection]);
   // Function to fetch assignments based on subject, class, and section
   const fetchAssignmentsDropdown = async (classId, sectionId, subjectId) => {
    try {
      const response = await fetch(
        "https://arizshad-002-site5.ktempurl.com/api/AssignmentCreate/GetAssignmentbySubId",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionStorage.getItem("token"),
          },
          body: JSON.stringify({ classId, sectionId, subjectId }),
        }
      );
      const data = await response.json();
      setAssignmentsDropdown(data);
    } catch (error) {
      console.error("Error fetching assignments:", error);
    }
  };

  // Fetch assignments whenever class, section, and subject are selected
  useEffect(() => {
    if (selectedClass && selectedSection && selectedSubject) {
      fetchAssignmentsDropdown(selectedClass, selectedSection, selectedSubject);
    } else {
      setAssignmentsDropdown([]);
    }
  }, [selectedClass, selectedSection, selectedSubject]);


  const fetchTeachers = async () => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/GetTeacher', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
      });
      const data = await response.json();
      setTeachers(data);
    } catch (error) {
      console.error('Error fetching teachers:', error);
    }
  };

  const fetchClasses = async (teacherId) => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/GetClass', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({
          teacherId,
        }),
      });
      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        throw new Error("Record Not Found");
      }
      setClasses(data);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const fetchSections = async (classId) => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/ddlSection_clsId', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({
          teacherId: selectedTeacher,
          classId,
        }),
      });
      const data = await response.json();
      setSections(data);
    } catch (error) {
      console.error('Error fetching sections:', error);
    }
  };

  const fetchSubjects = async (classId, sectionId, teacherId) => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/AssignmentCreate/Subjectddl', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({ classId, sectionId, teacherId }),
      });
      const data = await response.json();
      setSubjects(data);
    } catch (error) {
      console.error('Error fetching subjects:', error);
    }
  };


  const fetchAssignments = async () => {
    if (!selectedClass || !selectedSection || !selectedTeacher || !selectedAssignment) {
      console.error("All required fields are not selected.");
      return;
    }
  
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/GetAssignmentView', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({
          classId: selectedClass,
          sectionId: selectedSection,
          teacherId: selectedTeacher,
          assignmentId: selectedAssignment,
        }),
      });
      const data = await response.json();
      setAssignments(data);
      setFilteredAssignments(data);
    } catch (error) {
      console.error('Error fetching assignments:', error);
    }
  };

  useEffect(() => {
  if (selectedAssignment) {
    fetchAssignments();
  }
}, [selectedAssignment]);


  const handleFilterChange = () => {
    setFilteredAssignments(
      assignments.filter((assignment) =>
        (selectedTeacher === '' || assignment.teacher === selectedTeacher) &&
        (selectedClass === '' || assignment.className === selectedClass) &&
        (selectedSection === '' || assignment.sectionName === selectedSection)
      )
    );
  };

  const handleDescriptionChange = (e, id) => {
    const { value } = e.target;
    setAssignments((prev) =>
      prev.map((assignment) =>
        assignment.studentId === id ? { ...assignment, description: value } : assignment
      )
    );
    setFilteredAssignments((prev) =>
      prev.map((assignment) =>
        assignment.studentId === id ? { ...assignment, description: value } : assignment
      )
    );
  };

  const handleViewFile = (fileUrl) => {
    window.open(fileUrl, '_blank');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditingAssignment((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    const payload = checkedAssignments.map((assignment) => ({
      assignmentId: assignment.assignmentId,
      studentId: assignment.studentId,
      description: assignment.description,
      isStudentChecked: true,
    }));

    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/SaveAssignmentDescription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        // handle successful save
        console.log('Assignments saved successfully');
      } else {
        // handle error
        console.error('Error saving assignments');
      }
    } catch (error) {
      console.error('Error saving assignments:', error);
    }
  };

  const handleCancel = () => {
    // reset checked assignments
    setCheckedAssignments([]);
  };

  const handleCheckboxChange = (assignment) => {
    setCheckedAssignments((prev) => {
      if (prev.some((a) => a.studentId === assignment.studentId)) {
        return prev.filter((a) => a.studentId !== assignment.studentId);
      } else {
        return [...prev, assignment];
      }
    });
  };
  return (
    <div className="view-assignment">
      <h2 className="assignment-header">View Assignments</h2>
      <div className="filter-container">
     
        <select
          value={selectedTeacher}
          onChange={(e) => setSelectedTeacher(e.target.value)}
        >
          <option value="">Select Teacher</option>
          {teachers.map((teacher) => (
            <option key={teacher.employeeId} value={teacher.employeeId}>
              {teacher.employeeName}
            </option>
          ))}
        </select>
        {/* <select
  value={selectedAssignment}
  onChange={(e) => setSelectedAssignment(e.target.value)}
>
  <option value="">Select Assignment</option>
  {assignmentsDropdown.map((assignment) => (
    <option key={assignment.assignmentId} value={assignment.assignmentId}>
      {truncateDescription(assignment.assignmentDescription)}
    </option>
  ))}
</select> */}

        <select
          value={selectedClass}
          onChange={(e) => setSelectedClass(e.target.value)}
          disabled={!selectedTeacher}
        >
          <option value="">Select Class</option>
          {classes.map((cls) => (
            <option key={cls.classId} value={cls.classId}>
              {cls.className}
            </option>
          ))}
        </select>
        <select
          value={selectedSection}
          onChange={(e) => setSelectedSection(e.target.value)}
          disabled={!selectedClass}
        >
          <option value="">Select Section</option>
          {sections.map((sec) => (
            <option key={sec.sectionId} value={sec.sectionId}>
              {sec.sectionName}
            </option>
          ))}
        </select>
        <select value={selectedSubject} onChange={(e) => setSelectedSubject(e.target.value)} disabled={!selectedSection}>
          <option value="">Select Subject</option>
          {subjects.map((sub) => (
            <option key={sub.subjectId} value={sub.subjectId}>
              {sub.subjectName}
            </option>
          ))}
        </select>
        <select
          value={selectedAssignment}
          onChange={(e) => setSelectedAssignment(e.target.value)}
          disabled={!selectedSubject}
        >
          <option value="">Select Assignment</option>
          {assignmentsDropdown.map((assignment) => (
            <option key={assignment.assignmentId} value={assignment.assignmentId}>
              {assignment.assignmentName || assignment.assignmentDescription}
            </option>
          ))}
        </select>
      </div>
      <table>
        <thead>
          <tr>
            <th>Select</th>
            <th>Admission No</th>
            <th>Student</th>
            <th>Subject</th>
            <th>Class</th>
            <th>Section</th>
            <th>Submitted File</th>
            <th>Description</th>
            <th>Roll</th>
            <th>Remark</th>
          </tr>
        </thead>
        <tbody>
          {filteredAssignments.map((assignment) => (
            <tr key={assignment.studentId}>
              <td>
              <input
                  type="checkbox"
                  checked={checkedAssignments.some((a) => a.studentId === assignment.studentId)}
                  onChange={() => handleCheckboxChange(assignment)}
                />
              </td>
              <td>{assignment.admissionNo}</td>
              <td>{assignment.studentName}</td>
              <td>{assignment.subjectName}</td>
              <td>{assignment.className}</td>
              <td>{assignment.sectionName}</td>
              <td>
                <button onClick={() => handleViewFile(assignment.submittedFile)}>
                  View
                </button>
              </td>
              <td>
                <input
                  type="text"
                  value={assignment.description}
                  onChange={(e) => handleDescriptionChange(e, assignment.studentId)}
                  style={{ backgroundColor: '#f0f0f0' }}
                />
              </td>
              <td>{assignment.rollNo}</td>
              <td>{assignment.remark}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="action-buttons">
        <button onClick={handleSave}>Save</button>
        <button onClick={handleCancel}>Cancel</button>
      </div>


      {editingAssignment && (
        <div className="edit-form">
          <h3>Edit Assignment</h3>
          <form>
            <div className="form-group">
              <label>Admission No:</label>
              <input
                type="text"
                name="admissionNo"
                value={editingAssignment.admissionNo}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Student Name:</label>
              <input
                type="text"
                name="studentName"
                value={editingAssignment.studentName}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Subject:</label>
              <input
                type="text"
                name="subjectName"
                value={editingAssignment.subjectName}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Class:</label>
              <select
                name="className"
                value={editingAssignment.className}
                onChange={handleChange}
              >
                <option value="">Select Class</option>
                {classes.map((classOption) => (
                  <option key={classOption.classId} value={classOption.className}>
                    {classOption.className}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Section:</label>
              <select
                name="sectionName"
                value={editingAssignment.sectionName}
                onChange={handleChange}
              >
                <option value="">Select Section</option>
                {sections.map((sectionOption) => (
                  <option key={sectionOption.sectionId} value={sectionOption.sectionName}>
                    {sectionOption.sectionName}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Submitted File:</label>
              <input
                type="text"
                name="submittedFile"
                value={editingAssignment.submittedFile}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Description:</label>
              <textarea
                name="description"
                value={editingAssignment.description}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Roll:</label>
              <input
                type="text"
                name="rollNo"
                value={editingAssignment.rollNo}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Remark:</label>
              <textarea
                name="remark"
                value={editingAssignment.remark}
                onChange={handleChange}
              />
            </div>
            <button type="button" onClick={handleSave}>
              Save Changes
            </button>
          </form>
        </div>
      )}
    </div>
  );
}

export default ViewAssignment;
