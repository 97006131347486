import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";

const TimetablePage = () => {
  const [classValue, setClassValue] = useState("");
  const [sectionValue, setSectionValue] = useState("");
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [timetable, setTimetable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const periods = Array.from(
    new Set(timetable.map((item) => item.periodSequenceNo))
  ).sort();

  // Fetch classes
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await fetch(
          "https://arizshad-002-site5.ktempurl.com/api/Attendance/GetClass",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: sessionStorage.getItem("token"),
            },
            body: JSON.stringify({
              teacherId: sessionStorage.getItem("employeeId"),
            }),
          }
        );
        const data = await response.json();
        if (data.data === null && data.msg === "Record Not Found") {
          throw new Error("Record Not Found");
        }
        setClasses(data);
      } catch (error) {
        console.error("Error fetching classes:", error);
      }
    };

    fetchClasses();
  }, []);

  // Fetch sections
  const fetchSections = async (classId) => {
    try {
      const response = await fetch(
        "https://arizshad-002-site5.ktempurl.com/api/Teacher/ddlSection_clsId",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionStorage.getItem("token"),
          },
          body: JSON.stringify({ classId }),
        }
      );

      const data = await response.json();
      setSections(data);
    } catch (error) {
      console.error("Fetch sections error:", error);
    }
  };

  // Fetch timetable
  const fetchTimetable = async () => {
    if (!classValue || !sectionValue) {
      setSnackbarMessage("Please select both class and section.");
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(
        "https://arizshad-002-site5.ktempurl.com/api/TimeTable/GetTimeTable",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionStorage.getItem("token"),
          },
          body: JSON.stringify({
            classId: classValue,
            sectionId: sectionValue,
          }),
        }
      );

      const data = await response.json();
      if (!Array.isArray(data) || data.length === 0) {
        setTimetable([]);
        showSnackbar(
          "No timetable available for the selected class and section.",
          "info"
        );
      } else {
        setTimetable(data);
      }
    } catch (error) {
      console.error("Error fetching timetable:", error);
      setTimetable([]);
      setSnackbarMessage("An error occurred while fetching the timetable.");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClassChange = (event) => {
    const selectedClassId = event.target.value;
    setClassValue(selectedClassId);
    setSectionValue("");
    fetchSections(selectedClassId);
  };

  const handleSectionChange = (event) => {
    setSectionValue(event.target.value);
  };

  const showSnackbar = (message, severity = "info") => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = (_, reason) => {
    if (reason === "clickaway") return;
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // Group timetable data by periods
  const groupedData = timetable.reduce((acc, item) => {
    const period = item.periodSequenceNo;
    acc[period] = acc[period] || [];
    acc[period].push(item);
    return acc;
  }, {});

  return (
    <Box sx={{ maxWidth: 1000, margin: "40px auto", textAlign: "center" }}>
      <Typography variant="h4" gutterBottom>
        Time Table
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="class-select-label">Class</InputLabel>
            <Select
              labelId="class-select-label"
              value={classValue}
              onChange={handleClassChange}
            >
              {classes.map((cls) => (
                <MenuItem key={cls.classId} value={cls.classId}>
                  {cls.className}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="section-select-label">Section</InputLabel>
            <Select
              labelId="section-select-label"
              value={sectionValue}
              onChange={handleSectionChange}
              disabled={!classValue}
            >
              {sections.map((section) => (
                <MenuItem key={section.sectionId} value={section.sectionId}>
                  {section.sectionName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 3 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={fetchTimetable}
          disabled={loading}
        >
          {loading ? "Loading..." : "Show Timetable"}
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ marginTop: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={3}
                align="center"
                style={{ backgroundColor: "#3f51b5", color: "white" }}
              >
                Time
              </TableCell>
              {daysOfWeek.map((day, index) => (
                <TableCell
                  key={index}
                  align="center"
                  style={{ backgroundColor: "#3f51b5", color: "white" }}
                >
                  {day}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                style={{
                  backgroundColor: "#3f51b5",
                  color: "white",
                  borderRight: "1px solid #D3D3D3",
                }}
              >
                Start Time
              </TableCell>
              <TableCell
                align="center"
                style={{
                  backgroundColor: "#3f51b5",
                  color: "white",
                  borderRight: "1px solid #D3D3D3",
                }}
              >
                End Time
              </TableCell>
              <TableCell
                align="center"
                style={{
                  backgroundColor: "#3f51b5",
                  color: "white",
                  borderRight: "1px solid #D3D3D3",
                }}
              >
                Period
              </TableCell>
              {daysOfWeek.map((_, index) => (
                <TableCell
                  key={index}
                  style={{ backgroundColor: "#3f51b5", color: "white" }}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {periods.length > 0 ? (
              periods.map((period) => (
                <TableRow key={period}>
                  <TableCell align="center">
                    {groupedData[period]?.[0]?.startTime || "-"}
                  </TableCell>
                  <TableCell align="center">
                    {groupedData[period]?.[0]?.endTime || "-"}
                  </TableCell>
                  <TableCell align="center">Period {period}</TableCell>
                  {daysOfWeek.map((day, index) => {
                    const entry = groupedData[period]?.find(
                      (row) => row.dayOfWeek === day
                    );
                    return (
                      <TableCell key={index} align="center">
                        {entry ? (
                          <div>
                            <Typography
                              variant="body2"
                              style={{ fontWeight: "bold" }}
                            >
                              {entry.subjectName}
                            </Typography>
                            <Typography
                              variant="body2"
                              style={{ fontSize: "0.8rem" }}
                            >
                              {entry.teacherName}
                            </Typography>
                            <Typography variant="body2">
                              {entry.roomNo ? `Room ${entry.roomNo}` : ""}
                            </Typography>
                          </div>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={daysOfWeek.length + 3} align="center">
                  No timetable available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ zIndex: 1500 }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TimetablePage;
