import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './ViewAttendance.css';
import StudentDetailModal from './StudentDetailModal';

function ViewAttendance() {
  const [showModal, setShowModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [students, setStudents] = useState([]);
  const [streams, setStreams] = useState([]); 
  const [selectedTeacher, setSelectedTeacher] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedStream, setSelectedStream] = useState(''); 
  const [noStudentsModal, setNoStudentsModal] = useState(false); // State for no students modal

  const apiUrl = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem('token');

  const handleOpenModal = (student) => {
    setSelectedStudent(student.studentId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedStudent(null);
    setIsModalOpen(false);
  };

  const handleCloseNoStudentsModal = () => {
    setNoStudentsModal(false);
  };

  // Fetch Streams
  const fetchStreams = async () => {
    try {
      const response = await fetch(`${apiUrl}/Stream/GetAllStreams`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({ streamID: 0 }),
      });
      const data = await response.json();
  
      if (Array.isArray(data)) {
        setStreams(data);
      } else if (data && Array.isArray(data.streams)) {
        setStreams(data.streams);
      } else {
        setStreams([]);
      }
    } catch (error) {
      console.error('Error fetching streams:', error);
      setStreams([]);
    }
  };

  // Fetch Teachers
  const fetchTeachers = async () => {
    try {
      const response = await fetch(`${apiUrl}/Teacher/GetTeacher`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      });
      const data = await response.json();
      setTeachers(data);
    } catch (error) {
      console.error('Error fetching teachers:', error);
    }
  };

  // Fetch Classes
  const fetchClasses = async (teacherId) => {
    try {
      const response = await fetch(`${apiUrl}/Teacher/GetClass`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({ teacherId }),
      });
      const data = await response.json();
      setClasses(data);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

 // Fetch Attendance
 const fetchAttendance = async () => {
  // Clear previous students data when filters change
  setStudents([]);

  try {
    // Prepare body for the API call based on the filters
    const body = {
      stream: selectedStream ? selectedStream : "0", // If no stream selected, pass "0" for all streams
      classId: selectedClass,
      sessionId: 0, // Adjust this as needed if session handling is required
    };

    const response = await fetch(`${apiUrl}/Attendance/GetStudentAdmissionDetails`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    });

    const data = await response.json();

    if (!data || data.length === 0 || data === "null" || data.msg === "Record Not Found") {
      setNoStudentsModal(true);  // Show modal if no students found
      return;  // If no data, skip setting students
    }

    setStudents(data);  // Set the fetched data

  } catch (error) {
    console.error('Error fetching attendance:', error);
    setStudents([]); // Clear students in case of error
  }
};

  useEffect(() => {
    fetchTeachers();
    fetchStreams();
  }, []);

  useEffect(() => {
    if (selectedTeacher) {
      fetchClasses(selectedTeacher);
    } else {
      setClasses([]);
    }
  }, [selectedTeacher]);

  useEffect(() => {
    if (selectedClass) {
      fetchAttendance(); // Fetch data whenever the class is selected/changed
    }
  }, [selectedClass, selectedStream]);  // Call when either class or stream changes
  
  

  return (
    <div className="view-attendance">
      <h1>Student Attendance</h1>
      <div className="filter-container">
        <select value={selectedTeacher} onChange={(e) => setSelectedTeacher(e.target.value)}>
          <option value="">Select Teacher</option>
          {teachers.map((teacher) => (
            <option key={teacher.employeeId} value={teacher.employeeId}>
              {teacher.employeeName}
            </option>
          ))}
        </select>

        <select value={selectedClass} onChange={(e) => setSelectedClass(e.target.value)} disabled={!selectedTeacher}>
          <option value="">Select Class</option>
          {classes.map((cls) => (
            <option key={cls.classId} value={cls.classId}>
              {cls.className}
            </option>
          ))}
        </select>

        <select value={selectedStream} onChange={(e) => setSelectedStream(e.target.value)} disabled={!selectedClass}>
  <option value="">Select Stream</option>
  {streams.map((stream) => (
    <option key={stream.streamId} value={stream.streamName}>
      {stream.streamName}
    </option>
  ))}
</select>

      </div>

      <table>
        <thead>
          <tr>
            <th>Serial No</th>
            <th>Admission No</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Roll No</th>
            <th>Stream</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
  {students.length > 0 ? (
    students.map((student, index) => (
      <tr key={student.id}>
        <td>{index + 1}</td>
        <td>{student.admissionNo}</td>
        <td>{student.studentName}</td>
        <td>{student.studentLastName}</td>
        <td>{student.rollNo}</td>
        <td>{student.stream}</td>
        <td>
          <button onClick={() => handleOpenModal(student)}>View Details</button>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="7" style={{ textAlign: 'center', padding: '10px' }}>
        No Data Available
      </td>
    </tr>
  )}
</tbody>
      </table>

      <StudentDetailModal isOpen={isModalOpen} handleClose={handleCloseModal} studentId={selectedStudent} />

     {/* MUI Modal for No Students Found */}
     <Modal open={noStudentsModal} onClose={handleCloseNoStudentsModal}>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      borderRadius: 2,
      boxShadow: 24,
      p: 3,
    }}
  >
    {/* Close Button on the Top Right */}
    <IconButton
      onClick={handleCloseNoStudentsModal}
      sx={{
        position: 'absolute',
        top: 8,
        right: 8,
        color: 'gray',
      }}
    >
      <CloseIcon />
    </IconButton>

    <Typography variant="h6" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
      No Students Found
    </Typography>
    <Typography variant="body1" sx={{ mb: 3, textAlign: 'center' }}>
      No students are available for the selected stream.
    </Typography>

    <Box display="flex" justifyContent="center">
      <Button
        variant="outlined"
        sx={{
          color: 'black',
          borderColor: 'black',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)', // Light hover effect
            color: 'black', // Ensures text remains black on hover
            borderColor: 'black',
          },
        }}
        onClick={handleCloseNoStudentsModal}
      >
        Close
      </Button>
    </Box>
  </Box>
</Modal>
    </div>
  );
}

export default ViewAttendance;
